import { useState, useEffect } from "react";
import "./ImageSlider.css";
import base from "./base.png";
import roi from "./roi.png";
import comp from "./comp.png";

const images = [base, roi, comp];

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider-container">
      <h2 className="slider-heading">Our Statistics</h2>
      <div className="slider-wrapper">
        <img src={images[currentIndex]} alt="Slide" className="slider-image" />
        <button onClick={prevSlide} className="slider-button left">&#10094;</button>
        <button onClick={nextSlide} className="slider-button right">&#10095;</button>
      </div>
    </div>
  );
};

export default ImageSlider;
